import { Column } from "@tanstack/react-table";

import { DataTableSortDirection, DataTableSortState } from "../../types";
import { getMetaWithDefaults } from "../../utils";

export function useDataTableSortHelpers<TSortField extends string>({
	state,
	onChange,
}: {
	state?: DataTableSortState<TSortField>;
	onChange?: (sort: DataTableSortState<TSortField>) => void;
}) {
	if (!state || !onChange) {
		return {
			getIsColumnSortable: () => false,
			getIsColumnSorted: () => false,
			getSortHandler: () => () => {},
		};
	}

	const getIsColumnSortable = (column: Column<any>) => {
		const meta = getMetaWithDefaults(column);

		// The column is sortable when there's a custom sort field
		// declared or when the column is marked as sortable, and
		// when we can handle sorting changes
		return !!meta.sortField || !!meta.sortable;
	};

	const getIsColumnSorted = (column: Column<any>) => {
		const meta = getMetaWithDefaults(column);

		// The column is sorted when the current sort field (passed via props)
		// matches the custom sort field, when declared, otherwise the column's ID
		return state.field === meta.sortField || state.field === column.id;
	};

	const getNextSortDirection = (column: Column<any>) => {
		// If the column isn't sorted already, default to ascending order as the
		// initial direction
		if (!getIsColumnSorted(column)) {
			return DataTableSortDirection.Asc;
		}

		// Otherwise toggle the direction (asc -> desc, desc -> asc)
		return {
			ASC: DataTableSortDirection.Desc,
			DESC: DataTableSortDirection.Asc,
		}[state.direction];
	};

	const getSortHandler = (column: Column<any>) => {
		const meta = getMetaWithDefaults<any, TSortField>(column);

		const sortDirection = getNextSortDirection(column);

		return () =>
			onChange({
				field: meta.sortField ?? (column.id as TSortField),
				direction: sortDirection,
			});
	};

	return { getSortHandler, getIsColumnSortable, getIsColumnSorted };
}
